import React from "react"
import { propertyLetUrl } from "../../../site/urls"
import SearchResultsTemplate from "../../../templates/search-results-template"

const PropertyLet = props => {
  const location = props.location
  return (
    <SearchResultsTemplate
      locationName={propertyLetUrl}
      location={location}
      pCategoryType="residential"
      pType="lettings"
      pSubPath="let"
      pStatus="Let"
    />
  )
}

export default PropertyLet
